import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { graphql } from "gatsby";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Tick from "../assets/icon-tick.svg";
// Placeholder imports - these would need to be created
import ComprehensiveDB from "../assets/SmartRegs/icon-smart-regs-comprehensive-coverage.svg";
import AIAgent from "../assets/SmartRegs/icon-smart-regs-context-aware.svg";
import ROI from "../assets/SmartRegs/icon-smart-regs-significant-reduction.svg";
import RegulationSearch from "../assets/SmartRegs/icon-smart-regs-advanced-regulation-search.svg";
import AIAssistant from "../assets/SmartRegs/icon-smart-regs-ai-regulatory-agent.svg";
import DocumentIntegration from "../assets/SmartRegs/icon-smart-regs-smart-docs-integration.svg";
import RegulatoryFrameworks from "../assets/SmartRegs/graphic-smart-regs-comprehensive-global-coverage.svg";
import Interface from "../assets/SmartRegs/sr-interface.png";
import Desktop from "../assets/SmartRegs/graphic-smart-regs-desktop@2x.png";
import Chat from "../assets/SmartRegs/smartregs-ai.png";
// Component imports
import SmartRegsCard from "../components/SmartRegs/smartRegsCard";
import Seo from "../components/SEO";
import Started from "../components/Layout/started";
import AlsoInterestedIn from "../components/Layout/alsoInterestedIn";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";
import * as styles from "./taskcards.module.less";
import * as smartRegsStyles from "./smartregs.module.less";
import * as Scroll from "react-scroll";
const { scroller } = Scroll;

const RegulationSearchCard = {
  img: RegulationSearch,
  title: "Advanced Regulation Search",
  text: "Navigate over 120 international aviation regulatory frameworks with powerful search and comparison tools.",
};

const AIAssistantCard = {
  img: AIAssistant,
  title: "AI Regulatory Agent",
  text: "Get accurate, context-aware responses with direct references and citation links for transparency and verification.",
};

const DocumentIntegrationCard = {
  img: DocumentIntegration,
  title: "SmartDocs Integration",
  text: "Receive automated suggestions for document content based on regulatory requirements and instant notifications when regulations change.",
};

const cardsArray = [
  RegulationSearchCard,
  AIAssistantCard,
  DocumentIntegrationCard,
];

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
`;

const tick = (
  <div className={styles.tick}>
    <img src={Tick} alt="tick" />
  </div>
);

const TextWrapper = styled.div`
  @media screen and (max-width: 770px) {
    margin: 0 30px;
  }
  @media screen and (max-width: 450px) {
    margin: 0 20px;
  }
`;

const TickWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
  color: #242d41;
  margin-bottom: 40px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.9);
  margin-bottom: 20px;
`;

const TickSection = styled.div`
  display: flex;
  margin-bottom: 12px;
  font-size: 16px;
  letter-spacing: 0.02px;
  color: #242d41;
  line-height: 1.75;
`;

const GradientBackground = styled.div`
  padding-bottom: 100px;
  background-image: linear-gradient(to top, #f8fcff, #fff);
`;

const SectionWrapper = styled.div`
  max-width: 1440px;
  margin: auto;
`;

const CoverageImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
    border-radius: 6px;
  }
  @media screen and (max-width: 770px) {
    img {
      width: 50%;
      border-radius: 10px;
    }
  }
  @media screen and (max-width: 450px) {
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
`;

const RegulatorImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: 770px) {
    img {
      width: 50%;
      border-radius: 10px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }
  @media screen and (max-width: 450px) {
    img {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }
`;

const SmartRegs = (props) => {
  const { mainImage, aiPoweredImg, featuresImg } = props.data;

  const cards = cardsArray.map((card, key) => (
    <Col
      md={4}
      className={`${smartRegsStyles.smartRegsCardCol} ${pageStyles.coliPad}`}
    >
      <AnimationOnScroll
        animateOnce
        animateIn={
          key === 0
            ? "animate__fadeInLeft"
            : key === 1
            ? "animate__fadeInUp"
            : "animate__fadeInRight"
        }
        offset={0}
      >
        <SmartRegsCard
          key={card.title}
          img={card.img}
          title={card.title}
          text={card.text}
        />
      </AnimationOnScroll>
    </Col>
  ));

  return (
    <Layout>
      <Seo
        title="SmartRegs"
        description="AI-powered regulatory compliance for aviation"
      />
      <PageHeader
        header="SmartRegs. <br /><span>AI-Powered Compliance.</span>"
        subHeader="Transform your regulatory compliance with our advanced AI Agent framework."
        linkText="Schedule a demo"
        bigHeader
        bigImage={mainImage?.childImageSharp?.fluid}
        imageWithShadow
        whiteLink
        customImageClass="smartregs-hero"
        link="#schedule_demo_form"
        handleLinkScroll={(e) => {
          e.preventDefault();
          scroller.scrollTo("schedule_demo_form", {
            duration: 800,
            smooth: true,
            offset: -60,
          });
        }}
      />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={{ span: 6 }} className={pageStyles.coliPad}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
            >
              <TextWrapper>
                <h3 className={styles.title}>
                  Comprehensive Regulatory Compliance
                </h3>
                <Text>
                  SmartRegs provides an extensive database of over 120
                  international aviation regulatory frameworks, with continuous
                  updates and detailed amendment tracking.
                </Text>
                <Text>
                  Transform your compliance processes by leveraging our advanced
                  AI to provide intelligent, context-aware responses to your
                  regulatory questions.
                </Text>
                <div>
                  <TickSection>
                    {tick} Navigate complex regulations with ease through our
                    intuitive user interface.
                  </TickSection>
                  <TickSection>
                    {tick} Receive accurate, referenced responses with direct
                    citation links.
                  </TickSection>
                  <TickSection>
                    {tick} Automate document compliance checks and stay updated
                    with regulatory changes.
                  </TickSection>
                </div>
              </TextWrapper>
            </AnimationOnScroll>
          </Col>
          <Col md={6} className={`${pageStyles.coliPad}`}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInRight"
              offset={0}
            >
              <RegulatorImage>
                <img src={Interface} alt="SmartRegs Interface" />
              </RegulatorImage>
            </AnimationOnScroll>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={10}>
            <Row>
              <Col>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h3 className={styles.title}>We will help you achieve</h3>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
            </Row>
            <Row className={styles.topCardsParent}>
              <Col sm={4}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInUp"
                  offset={0}
                >
                  <div className={styles.topCardWrapper}>
                    <img src={ComprehensiveDB} alt="comprehensive database" />
                    <span>
                      Comprehensive coverage of global aviation regulations
                    </span>
                  </div>
                </AnimationOnScroll>
              </Col>
              <Col sm={4}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  <div className={styles.topCardWrapper}>
                    <img src={AIAgent} alt="AI agent" />
                    <span>
                      Intelligent, context-aware compliance assistance
                    </span>
                  </div>
                </AnimationOnScroll>
              </Col>
              <Col sm={4}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  <div className={styles.topCardWrapper}>
                    <img src={ROI} alt="return on investment" />
                    <span>Significant reduction in compliance workload</span>
                  </div>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={12}>
            <Row className={styles.titleRow}>
              <Col md={12}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h3 className={styles.title}>AI-Powered. Expert-Backed.</h3>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
              <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h4 className={styles.subTitle}>
                      SmartRegs combines cutting-edge AI with aviation
                      regulatory expertise to deliver accurate, context-aware
                      responses. All answers include direct references and
                      citation links, ensuring transparency and verification.
                    </h4>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
            </Row>
            <Row>
              <Col lg={7} md={6} className={pageStyles.coliPad}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  <div className={styles.screenImg}>
                    <img
                      src={Chat}
                      alt="AI-Powered Dashboard"
                      style={{
                        width: "100%",
                        borderRadius: "6px",
                        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                  </div>
                </AnimationOnScroll>
              </Col>
              <Col
                lg={4}
                md={5}
                className={`${pageStyles.coliPad} ${styles.margin50}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  <p className={styles.description}>
                    Our specialized AI agents can handle complex, concurrent
                    regulatory analyses, researching different regulatory
                    sections simultaneously for comprehensive answers.
                  </p>
                  <p className={styles.description}>
                    The platform features real-time monitoring of regulatory
                    changes, automated compliance verification, and intelligent
                    document integration that dramatically reduces workload
                    while improving accuracy for aviation compliance
                    professionals.
                  </p>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <GradientBackground>
        <SectionWrapper>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col md={{ span: 5 }} className={pageStyles.coliPad}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <TextWrapper>
                  <Title>Comprehensive Global Coverage</Title>
                  <Text>
                    SmartRegs provides access to over 120 international aviation
                    regulatory frameworks, updated continuously with detailed
                    amendment tracking and side-by-side comparisons.
                  </Text>
                  <Text>
                    Our platform is designed to support compliance
                    professionals, safety managers, document managers, and
                    regulatory authorities with tools that streamline regulatory
                    management and document integration.
                  </Text>
                  <div>
                    <Text>Available regulatory frameworks include:</Text>
                    <TickWrapper>
                      <TickSection>{tick} EASA Regulations</TickSection>
                      <TickSection>{tick} FAA Regulations</TickSection>
                      <TickSection>{tick} Transport Canada</TickSection>
                      <TickSection>{tick} UK Regulations</TickSection>
                      <TickSection>{tick} ICAO Standards</TickSection>
                      <TickSection>{tick} OTAR Regulations</TickSection>
                      <TickSection>
                        {tick} San Marino Aircraft Registry
                      </TickSection>
                      <TickSection>{tick} IOM (Isle of Man)</TickSection>
                    </TickWrapper>
                  </div>
                </TextWrapper>
              </AnimationOnScroll>
            </Col>
            <Col md={5} className={`${pageStyles.coliPad}`}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInRight"
                offset={0}
              >
                <CoverageImage>
                  <img src={RegulatoryFrameworks} alt="regulatory frameworks" />
                </CoverageImage>
              </AnimationOnScroll>
            </Col>
          </Row>
        </SectionWrapper>
      </GradientBackground>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <Row className={styles.titleRow}>
              <Col md={12}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h3 className={styles.title}>Powerful Compliance Tools</h3>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
              <Col md={{ span: 8, offset: 2 }}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h4 className={styles.subTitle}>
                      From targeted queries to comprehensive document compliance
                      checks, our tools provide the regulatory information and
                      guidance you need, when you need it.
                    </h4>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
            </Row>
            <Row
              className={`${styles.cardsHolder} ${smartRegsStyles.cardContainer}`}
            >
              {cards}
            </Row>
          </Col>
        </Row>
      </Container>
      <GradientSection>
        <Container>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col md={12}>
              <Row className={styles.titleRow}>
                <Col md={12}>
                  <CenteredDiv>
                    <AnimationOnScroll
                      animateOnce
                      animateIn="animate__fadeInDown"
                      offset={0}
                    >
                      <h3 className={styles.title}>
                        Designed for compliance professionals. Continuously
                        evolving.
                      </h3>
                    </AnimationOnScroll>
                  </CenteredDiv>
                </Col>
                <Col md={{ span: 8, offset: 2 }}>
                  <CenteredDiv>
                    <AnimationOnScroll
                      animateOnce
                      animateIn="animate__fadeInUp"
                      offset={0}
                    >
                      <h4 className={styles.subTitle}>
                        SmartRegs has been developed in collaboration with
                        compliance professionals, safety managers, and
                        regulatory authorities to ensure it meets the complex
                        demands of aviation compliance. Our platform
                        continuously evolves with regular updates and new
                        features.
                      </h4>
                    </AnimationOnScroll>
                  </CenteredDiv>
                </Col>
              </Row>
              <Row className={styles.benefitsRow}>
                <Col
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 5, offset: 1 }}
                  className={pageStyles.coliPad}
                >
                  <div className={styles.tickParent}>
                    <AnimationOnScroll
                      animateOnce
                      animateIn="animate__fadeInLeft"
                      offset={0}
                    >
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Access over 120 international aviation regulatory
                          frameworks
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Track amendments with detailed side-by-side
                          comparisons
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Receive intelligent, context-aware responses to
                          regulatory queries
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Verify all responses with direct references and
                          citation links
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Get automated suggestions for document content based
                          on regulations
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Easily navigate and search through complex regulatory
                          frameworks
                        </span>
                      </div>
                    </AnimationOnScroll>
                  </div>
                </Col>
                <Col lg={7} md={6} className={pageStyles.coliPad}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInRight"
                    offset={0}
                  >
                    <div className={styles.screenImg}>
                      <img
                        src={Desktop}
                        alt="SmartRegs Features"
                        style={{
                          width: "100%",
                          borderRadius: "6px",
                          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </div>
                  </AnimationOnScroll>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </GradientSection>
      <Container>
        <a id="schedule_demo_form" aria-label="Schedule a demo form" />
        <Started hubSpotFormId="31b02ccb-1b41-4cb0-9bf7-37cb90137bae" />
      </Container>
      <AlsoInterestedIn page="smartregs" />
    </Layout>
  );
};
export default SmartRegs;

export const query = graphql`
  query SmartRegsQuery {
    mainImage: file(relativePath: { eq: "SmartRegs/smartregs-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aiPoweredImg: file(
      relativePath: { eq: "SmartRegs/smartregs-screen-1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featuresImg: file(
      relativePath: { eq: "SmartRegs/smartregs-screen-2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
